import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  NgbModalConfig,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";

import { ImportContactsService } from "../services/import-contacts.service";
import { LoyaltyInquiryService } from "../services/loyalty-inquiry.service";
import { TransactionHistoryService } from "../services/transaction-history.service";
import { UpdateContactsService } from "../services/update-contacts.service";

import { ImportRequest } from "../dto/import-request";
import { ImportResponse } from "../dto/import-response";
import { ContactRequest } from "../dto/add-update-request";
import { ContactResponse } from "../dto/add-update-response";
import { LoyaltyInquiryRequest } from "../dto/loyalty-inquiry-request";
import { LoyaltyInquiryResponse } from "../dto/loyalty-inquiry-response";
import { CouponCodeEnquObj } from "../dto/coupon-inquiry-request";
import { CoupnCodeEnqResponse } from "../dto/coupon-inquiry-response";
import { CouponInquiryService } from "../services/coupon-inquiry.service";
import { CouponDiscountInfo } from "../model/coupon-model/CouponDiscountInfo";
import { LoyaltyTransactionHistoryRequest } from "../dto/loyalty-transaction-history-request";
import { LoyaltyTransactionHistoryResponse } from "../dto/loyalty-transaction-history-response";
import { LoyaltyMemberTransaction } from "../model/loyalty-model/loyalty-member-transaction";

export interface NgbModalOptions {
  size?: "sm" | "lg" | "xl";
}

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
  providers: [NgbModalConfig, NgbModal, DatePipe],
})
export class ProfileComponent implements OnInit {
  @ViewChild("closebutton") closebutton: ElementRef<HTMLElement>;
  modalReference: NgbModalRef;
  loyaltyInquiryRequest: LoyaltyInquiryRequest;
  loyaltyInquiryResponse: LoyaltyInquiryResponse;
  couponCodeEnquiryRequest: CouponCodeEnquObj;
  lastTrnsDate: String;
  loyaltyTransactionHistoryRequest: LoyaltyTransactionHistoryRequest;

  importRequest: ImportRequest;
  importResponse: ImportResponse;
  contactRequest: ContactRequest;
  contactResponse: ContactResponse;
  firstName: String;
  phoneNumber: String;
  emailAddress: String;
  birthDay: String;
  dobformat: String;
  anniversary: String;
  anniversaryFormat: String;
  lastName: String;
  address1: String;
  address2: String;
  city: String;
  state: String;
  country: String;
  zip: String;
  createdDate: String;
  loyaltyPoints: String;
  loyaltyAmount: String;
  totalRedeemableCurrency: String;
  nextTierMilestone: any;
  tierUpgradeCriteria: String;
  tierName: String;
  tierLevel: String;
  nextTierName: String;
  statusExpiry: String;
  route: ActivatedRoute;
  dateNow: Date;
  dformat: String;
  tformat: String;
  mobile: String;
  billValue: String;
  hostURL: String;
  billGUID: String;
  eventType: String;
  userName: String;
  orgID: String;
  token: String;
  lifeTimePurchaseVal: String;
  customerMembershipNumber: String;
  issuanceDesc: String;
  redeemDesc: String;
  proUpresp: String;
  loyaltyAmountExpire: String;
  loyaltyPointsExpire: String;
  transactions: String;
  currentTierValue: any;
  percentBar: any;
  totalTier: any;
  pageSize: any;
  nextPage: any;
  nextEnabled: boolean;
  prevEnabled: boolean;

  //Update Contact fields
  updateFirstName: String;
  updateLastName: String;
  updateMobileNumber: String;
  updateEmailAddress: String;
  updateDOB: String;
  updateAnniveraryDate: String;
  updateAddressLine1: String;
  updateAddressLine2: String;
  updateCity: String;
  updateState: String;
  updateCountry: String;
  updateZIP: String;
  tierImg: String;

  loyaltyMemberTransaction: LoyaltyMemberTransaction[];
  customerCouponResponseArray: CouponDiscountInfo[];

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    route: ActivatedRoute,
    private importContactsService: ImportContactsService,
    private loyaltyInquiryService: LoyaltyInquiryService,
    private couponInquiryService: CouponInquiryService,
    private transactionHistoryService: TransactionHistoryService,
    private updateContactsService: UpdateContactsService,
    private datepipe: DatePipe
  ) {
    this.route = route;
    // customize default values of modals used by this component tree
    config.backdrop = "static";
    config.keyboard = false;

    this.firstName = "";
    this.lastName = "";
    this.dateNow = new Date();
    this.eventType = this.route.snapshot.queryParamMap.get("eventType");
    this.billGUID = this.route.snapshot.queryParamMap.get("billGUID");
    this.mobile = this.route.snapshot.queryParamMap.get("mobile");
    this.billValue = this.route.snapshot.queryParamMap.get("billValue");
    this.userName = this.route.snapshot.queryParamMap.get("userName");
    this.orgID = this.route.snapshot.queryParamMap.get("orgID");
    this.token = this.route.snapshot.queryParamMap.get("token");
    this.hostURL = this.route.snapshot.queryParamMap.get("host");
    this.customerMembershipNumber = "";
    this.issuanceDesc = "";
    this.redeemDesc = "";
    this.proUpresp = "";
    this.pageSize = 20;
    this.percentBar = 1;
    this.lifeTimePurchaseVal = "";
    this.nextPage = 0;
    this.totalRedeemableCurrency = "0";
    this.nextEnabled = false;
    this.prevEnabled = false;
    this.tierImg = "Group_31@2x.png";
    this.dformat =
      [
        ("0" + this.dateNow.getFullYear()).slice(-4),
        ("0" + this.dateNow.getMonth() + 1).slice(-2),
        ("0" + this.dateNow.getDate()).slice(-2),
      ].join("-") +
      " " +
      [
        ("0" + this.dateNow.getHours()).slice(-2),
        ("0" + this.dateNow.getMinutes()).slice(-2),
        ("0" + this.dateNow.getSeconds()).slice(-2),
      ].join(":");

    this.tformat = [
      ("0" + this.dateNow.getHours()).slice(-2),
      ("0" + this.dateNow.getMinutes()).slice(-2),
      ("0" + this.dateNow.getSeconds()).slice(-2),
    ].join(":");

    this.contactRequest = {
      header: {
        contactList: "",
        contactSource: "",
        requestDate: "",
        requestId: "",
      },
      customer: {
        addressLine1: "",
        UDF1: "",
        UDF10: "",
        UDF11: "",
        UDF12: "",
        country: "",
        UDF13: "",
        deviceType: "",
        UDF14: "",
        UDF15: "",
        UDF2: "",
        UDF3: "",
        UDF4: "",
        UDF5: "",
        UDF6: "",
        UDF7: "",
        UDF8: "",
        UDF9: "",
        addressLine2: "",
        emailAddress: "",
        anniversary: "",
        birthday: "",
        city: "",
        creationDate: "",
        customerId: "",
        firstName: "",
        lastName: "",
        gender: "",
        homeStore: "",
        instanceId: "",
        membershipNumber: "",
        phone: "",
        postal: "",
        state: "",
        subsidiaryNumber: "",
        loyalty: {
          enrollCustomer: "",
          fingerprintValidation: "",
          password: "",
          mobileAppPreferences: {
            language: "",
            pushNotifications: "",
          },
        },
        suppress: {
          email: {
            isTrue: "",
            reason: "",
            timestamp: "",
          },
          phone: {
            isTrue: "",
            reason: "",
            timestamp: "",
          },
        },
      },
      user: {
        organizationId: "",
        token: "",
        userName: "",
      },
    };

    this.couponCodeEnquiryRequest = {
      COUPONCODEENQREQ: {
        HEADERINFO: {
          REQUESTID: "",
          PCFLAG: "",
          STORENUMBER: "",
          SOURCETYPE: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: "",
        },
        COUPONCODEINFO: {
          COUPONNAME: "",
          COUPONCODE: "",
          STORENUMBER: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: "", //for redemption
          CUSTOMERID: "",
          PHONE: "",
          EMAIL: "",
          DOCSID: "",
          DISCOUNTAMOUNT: "", //for smart promotion
          CARDNUMBER: "",
          APPLIEDCOUPONS: [],
        },
        PURCHASEDITEMS: [],
        USERDETAILS: {
          USERNAME: "",
          ORGID: "",
          TOKEN: "",
        },
      },
    };

    this.importRequest = {
      header: {
        employeeId: "",
        pcFlag: "",
        requestDate: "",
        requestId: "",
        storeNumber: "",
        terminalId: "",
        transactionDate: "",
        transactionId: "",
      },
      importType: "",
      lookup: {
        customer: [],
        emailAddress: "",
        membershipNumber: "",
        phone: "",
      },
      report: {
        contactList: "",
        contactSource: "",
        contactType: "",
        endDate: "",
        maxRecords: "",
        offset: "",
        reportType: "",
        startDate: "",
        store: "",
        totalCount: "",
      },
      user: {
        organizationId: "",
        token: "",
        userName: "",
      },
    };

    this.loyaltyTransactionHistoryRequest = {
      user: {
        organizationId: "",
        token: "",
        userName: "",
      },
      header: {
        requestDate: "",
        requestId: "",
        sourceType: "",
      },
      lookup: {
        brandName: "",
        cityName: "",
        country: "",
        docSID: "",
        emailAddress: "",
        highlightedOffers: "",
        lastFetchedTime: "",
        locality: "",
        membershipNumber: "",
        phone: "",
        receiptNumber: "",
        sortOrder: "",
        sortType: "",
        storeName: "",
        storeNumber: "",
        subsidiaryNumber: "",
      },
      report: {
        endDate: "",
        maxRecords: "",
        mode: "",
        offset: "",
        serviceType: "",
        source: "",
        startDate: "",
        store: "",
        transactionType: "",
        transactions: "",
      },
    };

    this.customerCouponResponseArray = [
      {
        COUPONNAME: "",
        COUPONCODE: "",
        COUPONTYPE: "",
        VALIDFROM: "",
        VALIDTO: "",
        OTPENABLED: "",
        DISCOUNTCRITERIA: "",
        EXCLUDEDISCOUNTEDITEMS: "",
        ACCUMULATEDISCOUNT: "",

        ELIGIBILITY: "",

        LOYALTYPOINTS: "",
        DISCOUNTINFO: [],

        LOYALTYVALUECODE: "",
        DESCRIPTION: "",
        APPLYATTRIBUTES: "",

        NUDGEPROMOCODE: "",
        NUDGEDESCRIPTION: "",
        APPLIEDCOUPONS: "",
      },
    ];
    this.loyaltyMemberTransaction = [
      {
        date: "",
        storeNumber: "",
        subsidiaryNumber: "",
        docSID: "",
        receiptNumber: "",
        amount: {
          enteredValue: "",
          receiptAmount: "",
          type: "",
          valueCode: "",
        },
        balances: [
          {
            amount: "",
            type: "",
            valueCode: "",
            difference: "",
            expire: "",
          },
          {
            amount: "",
            type: "",
            valueCode: "",
            difference: "",
            expire: "",
          },
        ],
      },
    ];

    this.loyaltyInquiryRequest = {
      header: {
        requestId: "",
        requestType: "",
        requestDate: "",
        pcFlag: "",
        subsidiaryNumber: "",
        storeNumber: "",
        employeeId: "",
        terminalId: "",
        docSID: "",
        receiptNumber: "",
        sourceType: "",
      },
      membership: {
        issueCardFlag: "",
        cardNumber: "",
        cardPin: "",
        fingerprintValidation: "",
        phoneNumber: "",
        createdDate: "",
        transactions: "",
      },
      customer: {
        customerId: "",
        instanceId: "",
        phone: "",
        membershipNumber: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        postal: "",
        country: "",
        homeStore: "",
        gender: "",
        creationDate: "",
        birthdayDateFormat: "",
        anniversaryDateFormat: "",
        birthday: "",
        anniversary: "",
        addressLine1: "",
        addressLine2: "",
        UDF1: "",
        UDF2: "",
        UDF3: "",
        UDF4: "",
        UDF5: "",
        UDF6: "",
        UDF7: "",
        UDF8: "",
        UDF9: "",
        UDF10: "",
        UDF11: "",
        UDF12: "",
        UDF13: "",
        UDF14: "",
        UDF15: "",
      },
      user: {
        userName: "",
        organizationId: "",
        token: "",
      },
      amount: {
        type: "", //Reward or gift
        enteredValue: "",
        valueCode: "",
        receiptAmount: "",
      },
    };
  }

  ngOnInit(): void {
    this.couponInquiry();
  }

  loyaltyInquiry() {
    this.loyaltyInquiryRequest.header.requestId = this.dateNow.toString();
    this.loyaltyInquiryRequest.header.requestDate = this.dformat;
    this.loyaltyInquiryRequest.header.storeNumber = "1";
    this.loyaltyInquiryRequest.header.docSID =
      this.route.snapshot.queryParamMap.get("billGUID");

    this.loyaltyInquiryRequest.membership.cardNumber =
      this.customerMembershipNumber;
    this.loyaltyInquiryRequest.membership.transactions = "Y";

    //this.loyaltyInquiryRequest.amount.receiptAmount = this.billValue

    this.loyaltyInquiryRequest.user.userName = this.userName;
    this.loyaltyInquiryRequest.user.organizationId = this.orgID;
    this.loyaltyInquiryRequest.user.token = this.token;

    console.log(
      "loyaltyInquiryRequest==>" + JSON.stringify(this.loyaltyInquiryRequest)
    );
    this.transactionHistory();

    this.loyaltyInquiryService
      .loyaltyInquiry(this.loyaltyInquiryRequest, this.hostURL)
      .subscribe(
        (resp) => {
          this.setLoyaltyValues(resp);
          console.log("loyaltyInquiryResponse==>" + JSON.stringify(resp));
          this.transactionHistory();
        },
        (err: any) => {
          console.log("Failure Response");
        }
      );
  }
  setLoyaltyValues(loyaltyInquiryResponse: LoyaltyInquiryResponse) {
    this.loyaltyPoints = loyaltyInquiryResponse.balances[0].amount;
    this.loyaltyAmount = loyaltyInquiryResponse.balances[1].amount;
    this.loyaltyPointsExpire = loyaltyInquiryResponse.balances[0].expire;
    this.loyaltyAmountExpire = loyaltyInquiryResponse.balances[1].expire;
    this.transactions = loyaltyInquiryResponse.membership.transactions;
    if (this.loyaltyPointsExpire == "") {
      this.loyaltyPointsExpire = "0";
    }
    if (this.loyaltyAmountExpire == "") {
      this.loyaltyAmountExpire = "0";
    }
    this.totalRedeemableCurrency =
      loyaltyInquiryResponse.additionalInfo.totalRedeemableCurrency;
    this.tierUpgradeCriteria =
      loyaltyInquiryResponse.membership.tierUpgradeCriteria;
    this.lifeTimePurchaseVal =
      loyaltyInquiryResponse.additionalInfo.lifeTimePurchaseValue;

    let rewardType: string;
    rewardType =
      this.tierUpgradeCriteria == "LifetimePoints" ? " Points" : " ₹";
    this.nextTierMilestone =
      loyaltyInquiryResponse.membership.nextTierMilestone.concat(rewardType);
    this.tierName = loyaltyInquiryResponse.membership.tierName;
    this.tierLevel = loyaltyInquiryResponse.membership.tierLevel;
    if (this.tierLevel == "Tier 2") {
      this.tierImg = "Group_32@2x.jpeg";
    } else if (this.tierLevel == "Tier 3") {
      this.tierImg = "Group_33@2x.png";
    }
    this.nextTierName = loyaltyInquiryResponse.membership.nextTierName;
    this.currentTierValue = loyaltyInquiryResponse.membership.currentTierValue;
    this.statusExpiry = loyaltyInquiryResponse.membership.expiry;
    this.totalTier =
      parseInt(this.currentTierValue) + parseInt(this.nextTierMilestone);
    this.percentBar = ((this.currentTierValue / this.totalTier) * 100).toFixed(
      3
    );
    if (this.percentBar < 1) {
      this.percentBar = 1;
    }
  }

  couponInquiry() {
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.HEADERINFO.REQUESTID =
      this.dateNow.toString();

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.COUPONCODE =
      "ALL";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.STORENUMBER =
      "1";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.PHONE =
      this.mobile;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.SUBSIDIARYNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTAMOUNT =
      this.billValue;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DOCSID =
      this.billGUID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DISCOUNTAMOUNT =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.EMAIL = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CUSTOMERID =
      "";

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.USERNAME =
      this.userName;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.ORGID =
      this.orgID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.TOKEN =
      this.token;

    console.log(JSON.stringify(this.couponCodeEnquiryRequest));
    this.couponInquiryService
      .couponInquiry(this.couponCodeEnquiryRequest, this.hostURL)
      .subscribe(
        (resp) => {
          this.setCouponValues(resp);
          console.log(JSON.stringify(resp));
          this.customerMembershipNumber =
            resp.COUPONCODERESPONSE.LOYALTYINFO.CARDNUMBER;
          this.issuanceDesc = resp.COUPONCODERESPONSE.LOYALTYINFO.ISSUANCEDESC;
          this.redeemDesc = resp.COUPONCODERESPONSE.LOYALTYINFO.REDEEMDESC;
          this.importContacts();
          this.loyaltyInquiry();
        },
        (err: any) => {
          console.log("Failure Response");
        }
      );
  }

  importContacts() {
    this.importRequest.header.requestId = this.dateNow.toString();
    this.importRequest.header.requestDate = this.dformat;

    this.importRequest.importType = "Lookup";
    this.importRequest.lookup.membershipNumber = this.customerMembershipNumber;
    this.importRequest.lookup.phone = this.mobile;

    this.importRequest.report.offset = "";
    this.importRequest.report.maxRecords = "";

    this.importRequest.user.userName = this.userName;
    this.importRequest.user.organizationId = this.orgID;
    this.importRequest.user.token = this.token;

    console.log(JSON.stringify(this.importRequest));
    this.importContactsService
      .importContact(this.importRequest, this.hostURL)
      .subscribe(
        (resp) => {
          console.log(JSON.stringify(resp));
          this.setContactFields(resp);
        },
        (err: any) => {
          console.log("Failure Response");
        }
      );
  }

  updateContacts(
    updateFirstName,
    updateLastName,
    updateMobileNumber,
    updateEmailAddress,
    updateDOB,
    updateAnniveraryDate,
    updateAddressLine1,
    updateAddressLine2,
    updateCity,
    updateState,
    updateCountry,
    updateZIP
  ) {
    (this.contactRequest.header.requestId = this.dateNow.toString()),
      (this.contactRequest.header.requestDate = this.dformat),
      (this.contactRequest.header.contactSource = "Mobile_App"),
      (this.contactRequest.header.contactList = "List1"),
      (this.contactRequest.customer.customerId = ""),
      (this.contactRequest.customer.instanceId = ""),
      (this.contactRequest.customer.membershipNumber =
        this.customerMembershipNumber), //representing membership
      (this.contactRequest.customer.firstName = updateFirstName.value),
      (this.contactRequest.customer.lastName = updateLastName.value),
      (this.contactRequest.customer.phone = updateMobileNumber.value),
      (this.contactRequest.customer.emailAddress = updateEmailAddress.value),
      (this.contactRequest.customer.addressLine1 = updateAddressLine1.value),
      (this.contactRequest.customer.addressLine2 = updateAddressLine2.value),
      (this.contactRequest.customer.city = updateCity.value),
      (this.contactRequest.customer.state = updateState.value),
      (this.contactRequest.customer.postal = updateZIP.value),
      (this.contactRequest.customer.country = updateCountry.value),
      (this.contactRequest.customer.birthday = updateDOB.value),
      (this.contactRequest.customer.anniversary = updateAnniveraryDate.value),
      (this.contactRequest.customer.gender = ""),
      (this.contactRequest.customer.homeStore = ""),
      (this.contactRequest.customer.deviceType = ""),
      (this.contactRequest.customer.creationDate = this.createdDate.substring(
        0,
        19
      )),
      (this.contactRequest.customer.loyalty.enrollCustomer = "");
    this.contactRequest.customer.loyalty.fingerprintValidation = "false";
    this.contactRequest.customer.loyalty.password = "";
    this.contactRequest.customer.loyalty.mobileAppPreferences.language = "";
    this.contactRequest.customer.loyalty.mobileAppPreferences.pushNotifications =
      "false";
    this.contactRequest.user.userName = this.userName;
    this.contactRequest.user.organizationId = this.orgID;
    this.contactRequest.user.token = this.token;

    console.log(JSON.stringify(this.contactRequest));
    if (this.contactRequest.customer.birthday !== "") {
      this.contactRequest.customer.birthday =
        updateDOB.value + " " + this.tformat;
    }
    if (this.contactRequest.customer.anniversary !== "") {
      this.contactRequest.customer.anniversary =
        updateAnniveraryDate.value + " " + this.tformat;
    }
    this.updateContactsService
      .updateContact(this.contactRequest, this.hostURL)
      .subscribe(
        (resp) => {
          console.log(JSON.stringify(resp));
          this.proUpresp = resp.status.message;
          this.importContacts();

          setTimeout(() => {
            this.proUpresp = "";
            this.modalReference.close();
          }, 3000);
        },
        (err: any) => {
          console.log("Failure Response");
          this.proUpresp = err;
          setTimeout(() => {
            this.proUpresp = "";
            this.modalReference.close();
          }, 3000);
        }
      );
  }

  transactionHistory() {
    this.loyaltyTransactionHistoryRequest.header.requestId =
      this.dateNow.toString();
    this.loyaltyTransactionHistoryRequest.header.requestDate = this.dformat;
    this.loyaltyTransactionHistoryRequest.header.sourceType = "Store";

    this.loyaltyTransactionHistoryRequest.lookup.emailAddress =
      this.emailAddress;
    this.loyaltyTransactionHistoryRequest.lookup.phone = this.mobile;
    this.loyaltyTransactionHistoryRequest.lookup.membershipNumber =
      this.customerMembershipNumber;

    this.loyaltyTransactionHistoryRequest.report.source = "All";
    this.loyaltyTransactionHistoryRequest.report.transactionType = "All";
    this.loyaltyTransactionHistoryRequest.report.transactions =
      this.transactions;
    this.loyaltyTransactionHistoryRequest.report.startDate = "";
    this.loyaltyTransactionHistoryRequest.report.endDate = "";
    this.loyaltyTransactionHistoryRequest.report.offset = this.nextPage;
    this.loyaltyTransactionHistoryRequest.report.maxRecords = "20";
    this.loyaltyTransactionHistoryRequest.report.store = "";

    this.loyaltyTransactionHistoryRequest.user.userName = this.userName;
    this.loyaltyTransactionHistoryRequest.user.organizationId = this.orgID;
    this.loyaltyTransactionHistoryRequest.user.token = this.token;

    console.log(JSON.stringify(this.loyaltyTransactionHistoryRequest));
    this.transactionHistoryService
      .transactionHistory(this.loyaltyTransactionHistoryRequest, this.hostURL)
      .subscribe(
        (resp) => {
          this.setTransactionHistory(resp);
        },
        (err: any) => {
          console.log(err);
        }
      );
  }
  setTransactionHistory(resp: LoyaltyTransactionHistoryResponse) {
    this.loyaltyMemberTransaction = resp.matchedCustomers[0].transactions;
    this.lastTrnsDate = resp.matchedCustomers && resp.matchedCustomers[0] && resp.matchedCustomers[0].transactions && resp.matchedCustomers[0].transactions[0] && resp.matchedCustomers[0].transactions[0].date ? resp.matchedCustomers[0].transactions[0].date : '';
    // debugger;
    console.log(this.loyaltyMemberTransaction.length);
    if (this.loyaltyMemberTransaction.length == 0) {
      this.nextEnabled = false;
    } else {
      this.nextEnabled = true;
    }
    if (this.nextPage > 0) {
      this.prevEnabled = true;
    } else {
      this.prevEnabled = false;
    }
  }

  setCouponValues(couponCodeEnquiryResponse: CoupnCodeEnqResponse) {
    this.customerCouponResponseArray =
      couponCodeEnquiryResponse.COUPONCODERESPONSE.COUPONDISCOUNTINFO;
    console.log("resp===>" + JSON.stringify(this.customerCouponResponseArray));
  }

  setContactFields(importResponse: ImportResponse) {
    this.firstName = importResponse.matchedCustomers[0].firstName;
    this.lastName = importResponse.matchedCustomers[0].lastName;
    this.phoneNumber = importResponse.matchedCustomers[0].phone;
    this.emailAddress = importResponse.matchedCustomers[0].emailAddress;
    this.birthDay = importResponse.matchedCustomers[0].birthday;
    this.dobformat =
      importResponse.matchedCustomers[0].birthdayDateFormat.slice(0, 10);
    this.anniversaryFormat =
      importResponse.matchedCustomers[0].anniversaryDateFormat.slice(0, 10);
    this.anniversary = importResponse.matchedCustomers[0].anniversary;
    this.address1 = importResponse.matchedCustomers[0].addressLine1;
    this.address2 = importResponse.matchedCustomers[0].addressLine2;
    this.city = importResponse.matchedCustomers[0].city;
    this.state = importResponse.matchedCustomers[0].state;
    this.country = importResponse.matchedCustomers[0].country;
    this.zip = importResponse.matchedCustomers[0].postal;
    this.createdDate = importResponse.matchedCustomers[0].creationDate;
  }

  onCancel() {
    console.log("hi 1");
    //  cancel(window);
  }
  open(content: any) {
    this.modalReference = this.modalService.open(content, { size: "lg" });
  }
  moreInfo(info: any) {
    this.modalService.open(info, { windowClass: "mod-md" });
  }
  openTransactionHistory(transactionHistory: any) {
    this.transactionHistory();
    console.log("==>XL");
    this.modalService.open(transactionHistory, { windowClass: "mod-xl" });
  }
  openTier(tier: any) {
    this.modalService.open(tier);
  }

  next(loyaltyMemberTransaction) {
    this.nextPage = this.nextPage + this.pageSize;
    if (this.nextEnabled) {
      this.transactionHistory();
    }
  }
  prev(loyaltyMemberTransaction) {
    this.nextPage = this.nextPage - this.pageSize;
    if (this.prevEnabled) {
      this.transactionHistory();
    }
  }
}
