import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoyaltyRedemtionServiceService } from "../services/loyalty-redemtion-service.service";
import { LoyaltyInquiryService } from "../services/loyalty-inquiry.service";
import { LoyaltyOtpService } from "../services/loyalty-otp.service";
import { LoyaltyRedemptionRequest } from "../dto/loyalty-redemption-request";
import { LoyaltyRedemptionResponse } from "../dto/loyalty-redemption-response";
import { LoyaltyInquiryRequest } from "../dto/loyalty-inquiry-request";
import {
  NgbModalConfig,
  NgbModal,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { LoyaltyInquiryResponse } from "../dto/loyalty-inquiry-response";
import { CouponCodeEnquObj } from "../dto/coupon-inquiry-request";
import { CoupnCodeEnqResponse } from "../dto/coupon-inquiry-response";
import { CouponInquiryService } from "../services/coupon-inquiry.service";
import { CouponDiscountInfo } from "../model/coupon-model/CouponDiscountInfo";
import { LoyaltyOTPRequest } from "../dto/LoyaltyOTPRequest";
import { MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { LoyaltyOTPResponse } from "../dto/LoyaltyOTPResponse";
import { MatDialog } from "@angular/material/dialog";
import { PointHiddenFieldParametersSuccess } from "../model/genesys-model/point-hidden-field-parameters";
import { PointHiddenFieldParametersFailed } from "../model/genesys-model/point-hidden-field-parameters";
import { CouponSuccessHiddenParamsSuccess } from "../model/genesys-model/coupon-hidden-field-parameters";
import { CouponSuccessHiddenParamsFailed } from "../model/genesys-model/coupon-hidden-field-parameters";
import { StatusInfo } from "../model/coupon-model/StatusInfo";

@Component({
  selector: "app-loyalty-redemption",
  templateUrl: "./loyalty-redemption.component.html",
  styleUrls: ["./loyalty-redemption.component.css"],
  providers: [
    NgbModalConfig,
    NgbModal,
    NgbActiveModal,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: "accent" },
    },
  ],
})
export class LoyaltyRedemptionComponent implements OnInit {
  @ViewChild("closebutton") closebutton: any;
  @ViewChild("content") content: ElementRef;
  @ViewChild("redemtionConfirm") redemtionConfirm: ElementRef;
  @ViewChild("couponRedemtionConfirm") couponRedemtionConfirm: ElementRef;
  loyaltyRedemptionRequest: LoyaltyRedemptionRequest;
  loyaltyRedemptionResponse: LoyaltyRedemptionResponse;
  loyaltyOTPRequest: LoyaltyOTPRequest;
  loyaltyOTPResponse: LoyaltyOTPResponse;
  loyaltyInquiryRequest: LoyaltyInquiryRequest;
  loyaltyInquiryResponse: LoyaltyInquiryResponse;
  couponCodeEnquiryRequest: CouponCodeEnquObj;
  couponCodeEnquiryResponse: CoupnCodeEnqResponse;
  pointHiddenFieldParametersSuccess: PointHiddenFieldParametersSuccess;
  pointHiddenFieldParametersFailed: PointHiddenFieldParametersFailed;
  couponSuccessHiddenParamsSuccess: CouponSuccessHiddenParamsSuccess;
  couponSuccessHiddenParamsFailed: CouponSuccessHiddenParamsFailed;

  eventType: String;
  billGUID: String;
  ISD: String;
  mobile: String;
  email: String;
  billValue: String;
  IsEmployee: String;
  userName: String;
  orgID: String;
  token: String;
  optEnabled: String;
  cuponOtpEnabled: String;
  hostURL: String;
  allowRedemption: Number;
  dateNow: Date;
  dformat: String;
  couponcode: String;
  otpUsed: Boolean;
  OTP: String;
  couponName: String;
  couponType: String;
  ACCUMULATEDISCOUNT: String;
  basis: String;
  factor: String;
  maxRedeemValue: String;
  minPurchaseValue: String;
  maxReceiptDiscount: String
  enableRedeemApply: boolean;
  redeemType: String;
  customerName: String;
  customerMembershipNumber: String;
  customerTotalRedeemableBalance: String;
  loyaltyPoints: String;
  customerCouponResponseArray: CouponDiscountInfo[];
  singleCustomerCouponResponseArray: CouponDiscountInfo[];
  statusInfo: StatusInfo;

  route: ActivatedRoute;
  modalRef: any;
  modalSuccess: any;
  storeNumber: String;
  modalRedemtionConfirmation: any;

  statusErrorCode: String;
  statusMessage: String;
  status: String;
  otpStatus: String;
  otpResponseVal: String;
  isCouponExist: Boolean;

  redemptionResponse: any;
  redeemPointsTbInputValue: string;
  redeemPointsTbInputValueOTP: String;
  allowRedumtionOnPromoItem: any;

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private loyaltyRedemptionService: LoyaltyRedemtionServiceService,
    private loyaltyInquiryService: LoyaltyInquiryService,
    private couponInquiryService: CouponInquiryService,
    route: ActivatedRoute,
    private loyaltyOtpService: LoyaltyOtpService,
    private dialog: MatDialog,
    public activeModal: NgbActiveModal
  ) {
    //Loyalty Inquiry intialization
    this.route = route;
    config.backdrop = "static";
    config.keyboard = false;

    this.eventType = "";
    this.billGUID = "";
    this.ISD = "";
    this.mobile = "";
    this.billValue = "";
    this.redeemType = "redemtionConfirm";
    this.IsEmployee = "";
    this.userName = "";
    this.orgID = "";
    this.storeNumber = "";
    this.token = "";
    this.hostURL = "";
    this.couponcode = "";
    this.couponName = "";
    this.couponType = "";
    this.ACCUMULATEDISCOUNT = "";
    this.maxRedeemValue = "";
    this.minPurchaseValue = "";
    this.basis = "";
    this.factor = "";
    this.optEnabled = "False";
    this.otpUsed = false;
    this.otpResponseVal = "";
    this.enableRedeemApply = false;
    this.isCouponExist = false;
    this.dateNow = new Date();
    this.allowRedumtionOnPromoItem = 0;
    this.allowRedemption = 0;
    this.OTP = "";
    this.dformat =
      [
        this.dateNow.getFullYear(),
        this.dateNow.getMonth() + 1,
        this.dateNow.getDate(),
      ].join("-") +
      " " +
      [
        this.dateNow.getHours(),
        this.dateNow.getMinutes(),
        this.dateNow.getSeconds(),
      ].join(":");

    this.customerName = "";
    this.customerMembershipNumber = "";
    this.customerTotalRedeemableBalance = "";

    this.redeemPointsTbInputValue = "";

    this.pointHiddenFieldParametersSuccess = {
      Allow_Point_Accrual: 0,
      Mobileno: "",
      OTP: "",
      Status: "",
      redeemedPoints: 0,
      redeemedValue: 0,
      ALLOW_POINT_REDEMPTION_BEFORE_BILL_SAVE: false,
      referenceNo: "",
    };
    this.pointHiddenFieldParametersFailed = {
      Errormessage: "",
      Mobileno: "",
      Status: "",
      referenceNo: "",
    };
    this.couponSuccessHiddenParamsSuccess = {
      ALLOW_POINT_ACCRUAL: 0,
      ALLOW_POINT_REDEMPTION: this.allowRedemption,
      ALLOW_REDEMPTION_ON_PROMO_ITEM: this.allowRedumtionOnPromoItem,
      BASIS: 0,
      COUPONCODE: "",
      FACTOR: 0,
      MAX_REDEEM_VALUE: 0,
      MIN_PURCHASE_VALUE: 0,
      OFFERCODE: "",
      REFERENCENO: "",
      STATUS: "",
    };
    this.couponSuccessHiddenParamsFailed = {
      Errormessage: "",
      Mobileno: "",
      Status: "",
      referenceNo: "",
    };

    this.loyaltyOTPRequest = {
      header: {
        requestId: "",
        requestType: "",
        requestDate: "",
        pcFlag: "",
        subsidiaryNumber: "",
        storeNumber: "",
        employeeId: "",
        terminalId: "",
        docSID: "",
        receiptNumber: "",
        sourceType: "",
      },
      otpCode: "",
      customer: {
        subsidiaryNumber: "",
        deviceType: "",
        createdDate: "",
        customerId: "",
        instanceId: "",
        phone: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        postal: "",
        country: "",
        gender: "",
        birthday: "",
        anniversary: "",
        addressLine1: "",
        addressLine2: "",
      },
      issueOnAction: "",
      user: {
        userName: "",
        organizationId: "",
        token: "",
      },
    };
    //CouponInfo Intialization
    this.customerCouponResponseArray = [
      {
        COUPONNAME: "",
        COUPONCODE: "",
        COUPONTYPE: "",
        VALIDFROM: "",
        VALIDTO: "",
        DISCOUNTCRITERIA: "",
        OTPENABLED: "",
        EXCLUDEDISCOUNTEDITEMS: "",
        ACCUMULATEDISCOUNT: "",

        ELIGIBILITY: "",

        LOYALTYPOINTS: "",
        DISCOUNTINFO: [],

        LOYALTYVALUECODE: "",
        DESCRIPTION: "",
        APPLYATTRIBUTES: "",

        NUDGEPROMOCODE: "",
        NUDGEDESCRIPTION: "",
        APPLIEDCOUPONS: "",
      },
    ];

    this.loyaltyInquiryRequest = {
      header: {
        requestId: "",
        requestType: "",
        requestDate: "",
        pcFlag: "",
        subsidiaryNumber: "",
        storeNumber: "",
        employeeId: "",
        terminalId: "",
        docSID: "",
        receiptNumber: "",
        sourceType: "",
      },
      membership: {
        issueCardFlag: "",
        cardNumber: "",
        cardPin: "",
        fingerprintValidation: "",
        phoneNumber: "",
        createdDate: "",
        transactions: "",
      },
      customer: {
        customerId: "",
        instanceId: "",
        phone: "",
        membershipNumber: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        postal: "",
        country: "",
        homeStore: "",
        birthdayDateFormat: "",
        anniversaryDateFormat: "",
        gender: "",
        creationDate: "",
        birthday: "",
        anniversary: "",
        addressLine1: "",
        addressLine2: "",
        UDF1: "",
        UDF2: "",
        UDF3: "",
        UDF4: "",
        UDF5: "",
        UDF6: "",
        UDF7: "",
        UDF8: "",
        UDF9: "",
        UDF10: "",
        UDF11: "",
        UDF12: "",
        UDF13: "",
        UDF14: "",
        UDF15: "",
      },
      user: {
        userName: "",
        organizationId: "",
        token: "",
      },
      amount: {
        type: "", //Reward or gift
        enteredValue: "",
        valueCode: "",
        receiptAmount: "",
      },
    };

    this.couponCodeEnquiryRequest = {
      COUPONCODEENQREQ: {
        HEADERINFO: {
          REQUESTID: "",
          PCFLAG: "",
          STORENUMBER: "",
          SOURCETYPE: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: "",
        },
        COUPONCODEINFO: {
          COUPONNAME: "",
          COUPONCODE: "",
          STORENUMBER: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: "", //for redemption
          CUSTOMERID: "",
          PHONE: "",
          EMAIL: "",
          DOCSID: "",
          DISCOUNTAMOUNT: "", //for smart promotion
          CARDNUMBER: "",
          APPLIEDCOUPONS: [],
        },
        PURCHASEDITEMS: [],
        USERDETAILS: {
          USERNAME: "",
          ORGID: "",
          TOKEN: "",
        },
      },
    };

    //Loyalty Redemption intialization
    this.loyaltyRedemptionRequest = {
      header: {
        requestId: "",
        requestType: "",
        requestDate: "",
        pcFlag: "",
        subsidiaryNumber: "",
        storeNumber: "",
        employeeId: "",
        terminalId: "",
        docSID: "",
        receiptNumber: "",
        sourceType: "",
      },
      membership: {
        issueCardFlag: "",
        cardNumber: "",
        cardPin: "",
        fingerprintValidation: "",
        phoneNumber: "",
        createdDate: "",
        transactions: "",
      },
      amount: {
        type: "", //Reward or gift
        enteredValue: "",
        valueCode: "",
        receiptAmount: "",
      },
      otpCode: "",
      discounts: {
        appliedPromotion: "",
        promotions: [],
      },
      customer: {
        customerId: "",
        instanceId: "",
        firstName: "",
        lastName: "",
        phone: "",
        emailAddress: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postal: "",
        country: "",
        birthday: "",
        anniversary: "",
        gender: "",
        subsidiaryNumber: "",
        createdDate: "", //  is created for setting created_date while implementing bulk enrollment utility
        deviceType: "",
      },
      user: {
        userName: "",
        organizationId: "",
        token: "",
      },
    };
  }

  ngOnInit(): void {
    this.eventType = this.route.snapshot.queryParamMap.get("eventType");
    this.billGUID = this.route.snapshot.queryParamMap.get("billGUID");
    this.ISD = this.route.snapshot.queryParamMap.get("ISD");
    this.mobile = this.route.snapshot.queryParamMap.get("mobile");
    this.billValue = this.route.snapshot.queryParamMap.get("billValue");
    this.IsEmployee = this.route.snapshot.queryParamMap.get("IsEmployee");
    this.userName = this.route.snapshot.queryParamMap.get("userName");
    this.orgID = this.route.snapshot.queryParamMap.get("orgID");
    this.token = this.route.snapshot.queryParamMap.get("token");
    this.hostURL = this.route.snapshot.queryParamMap.get("host");
    this.storeNumber = this.route.snapshot.queryParamMap.get("store");
    this.couponInquiry();
    //this.loyaltyInquiry();
    //console.log(this.eventType);
    //console.log(this.billGUID);
    //console.log(this.mobile);
    if (this.eventType == "pointsRedemption") {
      this.enableRedeemApply = true;
    }
  }

  couponInquiry() {
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.HEADERINFO.REQUESTID =
      this.dateNow.toString();
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.COUPONCODE =
      "DISCOUNTS";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.STORENUMBER =
      this.storeNumber;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.PHONE =
      this.mobile;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.SUBSIDIARYNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTAMOUNT =
      this.billValue;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DOCSID =
      this.billGUID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DISCOUNTAMOUNT =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.EMAIL = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CUSTOMERID =
      "";

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.USERNAME =
      this.userName;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.ORGID =
      this.orgID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.TOKEN =
      this.token;
    console.log(JSON.stringify(this.couponCodeEnquiryRequest));
    this.couponInquiryService
      .couponInquiry(this.couponCodeEnquiryRequest, this.hostURL)
      .subscribe(
        (resp) => {
          this.setCouponValues(resp);
          //console.log(JSON.stringify(resp));
          this.loyaltyInquiry(resp);
        },
        (err: any) => {
          console.log("Failure Response");
        }
      );
  }

  inputCouponInquiry() {
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.HEADERINFO.REQUESTID =
      this.dateNow.toString();
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.COUPONCODE =
      this.couponcode;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.STORENUMBER =
      this.storeNumber;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.PHONE =
      this.mobile;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.SUBSIDIARYNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTAMOUNT =
      this.billValue;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DOCSID =
      this.billGUID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DISCOUNTAMOUNT =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.EMAIL = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CUSTOMERID =
      "";

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.USERNAME =
      this.userName;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.ORGID =
      this.orgID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.TOKEN =
      this.token;
    //console.log(JSON.stringify(this.couponCodeEnquiryRequest));
    this.couponInquiryService
      .couponInquiry(this.couponCodeEnquiryRequest, this.hostURL)
      .subscribe(
        (resp) => {
          this.setSingleCouponValues(resp);
        },
        (err: any) => {
          console.log("Failure Response");
        }
      );
  }

  loyaltyInquiry(resp) {
    this.optEnabled = resp.COUPONCODERESPONSE.LOYALTYINFO.OTPENABLED;
    this.customerName = resp.COUPONCODERESPONSE.LOYALTYINFO.CUSTOMERNAME;
    this.customerMembershipNumber =
      resp.COUPONCODERESPONSE.LOYALTYINFO.CARDNUMBER;
      this.email=resp.COUPONCODERESPONSE.LOYALTYINFO.EMAIL;
    if (resp.COUPONCODERESPONSE.LOYALTYINFO.ALLOWREDEMPTION === "TRUE") {
      this.allowRedemption = 1;
    }
    this.customerTotalRedeemableBalance =
      resp.COUPONCODERESPONSE.LOYALTYINFO.REDEEMABLEAMOUNT;
    this.loyaltyPoints = resp.COUPONCODERESPONSE.LOYALTYINFO.BALANCES[1].AMOUNT;
  }

  setCouponValues(couponCodeEnquiryResponse: CoupnCodeEnqResponse) {
    this.customerCouponResponseArray =
      couponCodeEnquiryResponse.COUPONCODERESPONSE.COUPONDISCOUNTINFO;
    //console.log('resp===>' + JSON.stringify(this.customerCouponResponseArray));
  }
  setSingleCouponValues(singleCouponCodeEnquiryResponse: CoupnCodeEnqResponse) {
    this.singleCustomerCouponResponseArray =
      singleCouponCodeEnquiryResponse.COUPONCODERESPONSE.COUPONDISCOUNTINFO;
    for (var val of this.singleCustomerCouponResponseArray) {
      //console.log('val==>' + val.COUPONCODE);
      if (val.COUPONCODE == this.couponcode) {
        this.isCouponExist = true;
        this.couponcode = val.COUPONCODE;
        this.couponName = val.COUPONNAME;
        this.couponType = val.COUPONTYPE;
        this.ACCUMULATEDISCOUNT = val.ACCUMULATEDISCOUNT;
        this.factor = val.DISCOUNTINFO[0].VALUE;
        this.maxRedeemValue = val.DISCOUNTINFO[0].MAXRECEIPTDISCOUNT;
        this.minPurchaseValue = val.DISCOUNTINFO[0].MINPURCHASEVALUE;
        this.basis = val.DISCOUNTINFO[0].VALUECODE;
        if (this.basis == "P") {
          this.basis = "0";
        } else {
          this.basis = "1";
        }
      }
    }
    if (this.isCouponExist) {
      this.couponSuccess();
    } else {
      console.log("err");
      this.statusInfo = singleCouponCodeEnquiryResponse.COUPONCODERESPONSE.STATUSINFO;
      this.couponErrForInquiry(this.statusInfo.ERRORCODE);
    }
  }
  setOtpResposne(loyaltyOTPResponse: LoyaltyOTPResponse) {
    this.statusErrorCode = loyaltyOTPResponse.status.errorCode;
    this.statusMessage = loyaltyOTPResponse.status.message;
    this.status = loyaltyOTPResponse.status.status;
  }
  setLoyaltyRedemptionResponse(
    errorCode: String,
    message: String,
    status: String
  ) {
    this.statusErrorCode = errorCode;
    this.statusMessage = message;
    this.status = status;
  }

  redemption(redeemPointsTbInput: any, message: any) {
    this.loyaltyRedemption(redeemPointsTbInput, message);
  }
  cpRedemption(redeemCouponTbInput: any, message: any) {
    this.couponRedemption(redeemCouponTbInput, message);
  }

  couponRedemption(redeemCouponTbInput: any, message: any) {
    this.modalRedemtionConfirmation.close();
    this.modalSuccess = this.modalService.open(message);
    if (this.eventType == "pointsRedemption") {
      this.setLoyaltyRedemptionResponse(
        "1",
        "Event type is wrongly defined",
        "Bad Request"
      );
    } else {
      this.isCouponExist = false;
      for (var val of this.customerCouponResponseArray) {
        // console.log('val==>' + val.COUPONCODE);
        if (val.COUPONCODE == redeemCouponTbInput.value) {
          if (typeof val.DISCOUNTINFO === "undefined") {
            this.couponErr();
          } else {
            this.isCouponExist = true;
            this.couponcode = val.COUPONCODE;
            this.couponName = val.COUPONNAME;
            this.couponType = val.COUPONTYPE;
            this.ACCUMULATEDISCOUNT = val.ACCUMULATEDISCOUNT;
            this.factor = val.DISCOUNTINFO[0].VALUE;
            this.maxRedeemValue = val.DISCOUNTINFO[0].MAXRECEIPTDISCOUNT;
            this.minPurchaseValue = val.DISCOUNTINFO[0].MINPURCHASEVALUE;
            this.basis = val.DISCOUNTINFO[0].VALUECODE;
            if (this.basis == "P") {
              this.basis = "0";
            } else {
              this.basis = "1";
            }
          }
        }
      }
      //console.log('couponext=>'+this.isCouponExist);
      if (this.isCouponExist) {
        this.couponSuccess();
      } else {
        if (
          this.couponcode !== redeemCouponTbInput.value &&
          redeemCouponTbInput.value !== ""
        ) {
          this.couponcode = redeemCouponTbInput.value;
          this.inputCouponInquiry();
        } else {
          this.couponErrForInquiry(this.statusInfo.ERRORCODE);
        }
      }

      //console.log('isCouponExist==>' + isCouponExist);
    }
  }

  couponSuccess() {
    const message =
      "Redemption was successful for coupon :" + this.couponcode + ".";
    this.setLoyaltyRedemptionResponse("0", message, "Successful");
    this.submitHiddenRequest(message, "couponRedemption", "0", this.couponcode);
  }
  couponErrForInquiry(errorCode) {
    console.log(errorCode)
    let message : string;
    switch (errorCode) {
      case '100012':
        message = "Coupon Code does not exist";
        break;
      case '100013':
        message = "Redemption limit exceeded";
        break;
      case '100014':
        message = "Entered Coupon Code Already Redeemed";
        break;
      case '100015':
        message = "Coupon Code Expired";
        break;
      case '100016':
        message = "Coupon Code is not applicable to any of the purchased items";
        break;
      case '100019':
        message = "No Coupon Code found for redemption with loyalty points";
        break;
      case '100918':
        message = "Coupon Code is not Issued";
        break;
      case '100919':
        message = "This Coupon Code cannot be redeemed(Promotion status is Paused)";
        break;
      case '100920':
        message = "Entered Coupon Code Already Redeemed";
        break;
      case '100921' :
        message = "Redemption limit exceeded";
        break;
      case '100931':
        message = "This Coupon Code cannot be redeemed(Promotion status is Active)";
        break;
      default:
        message = this.statusInfo.MESSAGE;
    }
    this.setLoyaltyRedemptionResponse("1", message, "Failure");
    this.submitHiddenRequest(message, "couponRedemption", "1", this.couponcode);
  }
  couponErr() {
    const message = "Coupon does not exist.";
    this.setLoyaltyRedemptionResponse("1", message, "Failure");
    this.submitHiddenRequest(message, "couponRedemption", "1", this.couponcode);
  }

  loyaltyRedemption(redeemPointsTbInput: any, successMessage: any) {
    this.modalRedemtionConfirmation.close();
    this.modalSuccess = this.modalService.open(successMessage);
    if (this.eventType == "couponRedemption") {
      this.setLoyaltyRedemptionResponse(
        "1",
        "Event type is wrongly defined",
        "Bad Request"
      );
    } else {
      if (
        Number(redeemPointsTbInput.value) <= Number(this.billValue) &&
        Number(redeemPointsTbInput.value) <=
        Number(this.customerTotalRedeemableBalance) &&
        Number(redeemPointsTbInput.value) != 0
      ) {
        this.setLoyaltyRedemptionResponse(
          "1",
          "Redemption was successful for ₹" + redeemPointsTbInput.value + ".",
          "Successful"
        );
        this.submitHiddenRequest(
          "Redemption was successful",
          "pointsRedemption",
          "0",
          redeemPointsTbInput.value
        );
      } else if (
        Number(redeemPointsTbInput.value) >
        Number(this.customerTotalRedeemableBalance)
      ) {
        const message =
          "Redeemable value should be cannot exceed total redeemable amount ₹" +
          this.customerTotalRedeemableBalance;
        this.setLoyaltyRedemptionResponse("1", message, "Failure");
        console.log(redeemPointsTbInput);
        this.submitHiddenRequest(
          message,
          "pointsRedemption",
          "1",
          redeemPointsTbInput.value
        );
      } else {
        const message =
          "Redeemable value should be valid and cannot exceed invoice amount ₹" +
          this.billValue;
        this.setLoyaltyRedemptionResponse("1", message, "Failure");
        console.log(redeemPointsTbInput);
        this.submitHiddenRequest(
          message,
          "pointsRedemption",
          "1",
          redeemPointsTbInput.value
        );
      }
    }
  }

  loyaltyRedemptionDialog() {
    if (this.optEnabled == "True" && !this.otpUsed) {
      this.loyaltyOTPDialog();
    } else {
      this.modalRedemtionConfirmation = this.modalService.open(
        this.redemtionConfirm,
        {
          size: "md",
        }
      );
    }
  }
  couponRedemptionDialog() {
    this.redeemType = "couponRedemtionConfirm";
    if (this.redeemPointsTbInputValueOTP == "True" && !this.otpUsed) {
      this.loyaltyOTPDialog();
    } else {
      this.modalRedemtionConfirmation = this.modalService.open(
        this.couponRedemtionConfirm,
        {
          size: "md",
        }
      );
    }
  }

  loyaltyOTP() {
    this.loyaltyOTPRequest.header.requestId = this.dateNow.toString();
    this.loyaltyOTPRequest.header.requestDate = this.dformat;
    this.loyaltyOTPRequest.header.requestType = "Issue";
    this.loyaltyOTPRequest.header.storeNumber = this.storeNumber;
    this.loyaltyOTPRequest.header.docSID = this.billGUID;

    this.loyaltyOTPRequest.customer.phone = this.mobile;
    this.loyaltyOTPRequest.customer.emailAddress = this.email;

    this.loyaltyOTPRequest.user.userName = this.userName;
    this.loyaltyOTPRequest.user.organizationId = this.orgID;
    this.loyaltyOTPRequest.user.token = this.token;

    if (this.eventType.toLowerCase() == "pointsredemption")
    this.loyaltyOTPRequest.issueOnAction = "Redemption"

    //console.log(this.loyaltyOTPRequest);
    //console.log('resp===>' + JSON.stringify(this.loyaltyOTPRequest));
    this.loyaltyOtpService
      .loyaltyOtp(this.loyaltyOTPRequest, this.hostURL)
      .subscribe(
        (resp) => {
          this.otpResponseVal = resp.otpCode;
        },
        (err: any) => {
          // console.log('Failure Response');
        }
      );
  }

  submitHiddenRequest(
    message: String,
    eventType: String,
    errorCode: String,
    points: String
  ) {
    if (eventType == "pointsRedemption")
      this.loyaltySubmit(errorCode, points, message);
    else if (eventType == "couponRedemption")
      this.couponSubmit(errorCode, message);
    else console.log("Bad Request : Event type is wrongly defined");
  }

  loyaltySubmit(errorCode: String, points: String, message: String) {
    if (errorCode == "0") {
      let point = points == "" ? 0 : Number(points);
      this.redemptionResponse = this.pointHiddenFieldParametersSuccess = {
        Allow_Point_Accrual: 1,
        Mobileno: this.mobile,
        OTP: this.OTP,
        Status: "Success",
        redeemedPoints: point,
        redeemedValue: point,
        ALLOW_POINT_REDEMPTION_BEFORE_BILL_SAVE: false,
        referenceNo: this.billGUID,
      };
      console.log(
        "redemptionResponse===>" + JSON.stringify(this.redemptionResponse)
      );
      this.redemptionResponse = JSON.stringify(this.redemptionResponse);
    } else {
      this.redemptionResponse = this.pointHiddenFieldParametersFailed = {
        Mobileno: this.mobile,
        Status: "Failed",
        referenceNo: this.billGUID,
        Errormessage: message,
      };
      this.redemptionResponse = JSON.stringify(this.redemptionResponse);
      console.log(
        "redemptionResponse===>" + JSON.stringify(this.redemptionResponse)
      );
    }
    window.parent.postMessage(this.redemptionResponse, "*");
  }
  couponSubmit(errorCode: String, message: String) {
    if (this.ACCUMULATEDISCOUNT.toLocaleUpperCase() === "TRUE") {
      this.allowRedumtionOnPromoItem = 1;
    } else {
      this.allowRedumtionOnPromoItem = 0;
    }
    if (errorCode == "0") {
      this.redemptionResponse = {
        ALLOW_POINT_ACCRUAL: 1,
        ALLOW_POINT_REDEMPTION: this.allowRedemption,
        ALLOW_REDEMPTION_ON_PROMO_ITEM: this.allowRedumtionOnPromoItem,
        ALLOW_COUPON_REDEMPTION_BEFORE_BILL_SAVE: false,
        BASIS: this.basis,
        COUPONCODE: this.couponcode,
        FACTOR: this.factor,
        MAX_REDEEM_VALUE: this.maxRedeemValue,
        MIN_PURCHASE_VALUE: this.minPurchaseValue,
        OFFERCODE: this.couponName,
        REFERENCENO: this.billGUID,
        MOBILENO: this.mobile,
        OTP: this.OTP,
        STATUS: "Success",
      };
    } else {
      this.redemptionResponse = {
        Errormessage: message,
        Mobileno: this.mobile,
        Status: "Failed",
        referenceNo: this.billGUID,
      };
    }
    this.redemptionResponse = JSON.stringify(this.redemptionResponse);
    console.log("couponResponse===>" + JSON.stringify(this.redemptionResponse));

    window.parent.postMessage(this.redemptionResponse, "*");
  }

  loyaltyOTPDialog() {
    //this.modalRedemtionConfirmation.close();
    this.modalRef = this.modalService.open(this.content, { size: "mm" });
    this.loyaltyOTP();
  }

  loyaltyOTPAcknowledge(otpCode: any) {
    this.OTP = otpCode.value;

    this.loyaltyOTPRequest.customer.phone = this.mobile;
    this.loyaltyOTPRequest.customer.emailAddress = "";

    this.loyaltyOTPRequest.user.userName = this.userName;
    this.loyaltyOTPRequest.user.organizationId = this.orgID;
    this.loyaltyOTPRequest.user.token = this.token;

    //console.log(this.loyaltyOTPRequest);
    console.log("resp===>" + JSON.stringify(this.otpResponseVal));
    if (this.eventType.toLowerCase() == "couponredemption") {
      this.loyaltyOTPRequest.header.requestId = this.dateNow.toString();
      this.loyaltyOTPRequest.header.requestDate = this.dformat;
      this.loyaltyOTPRequest.header.requestType = "Acknowledge";
      this.loyaltyOTPRequest.header.storeNumber = this.storeNumber;
      this.loyaltyOTPRequest.header.docSID = this.billGUID;

      this.loyaltyOTPRequest.otpCode = otpCode.value;
      this.loyaltyOtpService
        .loyaltyOtp(this.loyaltyOTPRequest, this.hostURL)
        .subscribe(
          (resp) => {
            console.log(resp);
            this.otpStatus = resp.status.message;
            if (resp.status.status == "Success") {
              this.modalRef.close();
              this.otpUsed = true;
              if (this.redeemType === "couponRedemtionConfirm") {
                this.couponRedemptionDialog();
              }
            }
            //this.modalRef.close();

            this.setOtpResposne(resp);

            console.log(resp);

            //this.modalSuccess = this.modalService.open(successMessage);
          },
          (err: any) => {
            this.otpStatus = err;
          }
        );
    } else {
      if (this.OTP == this.otpResponseVal) {
        this.modalRef.close();

        this.otpUsed = true;
        this.loyaltyRedemptionDialog();
      } else {
        this.otpStatus = "Invalid Code";
      }
    }
  }
  radioChangeForCoupon(event: any, otpval: any) {
    this.redeemPointsTbInputValue = event.target.value;
    this.redeemPointsTbInputValueOTP = otpval;
  }
  manualCouponOnchange(event: any) {
    for (var val of this.customerCouponResponseArray) {
      // console.log('val==>' + val.COUPONCODE);
      if (val.COUPONCODE == event.target.value) {
        this.redeemPointsTbInputValueOTP = val.OTPENABLED;
      }
    }
  }
  radioSelection(event: any) {
    //if(event.target.value === ''){}
  }
}
